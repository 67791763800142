import React, { useEffect } from "react";
//@ts-ignore next line
import { usePaymentInputs } from "react-payment-inputs";

import {
  CardData,
  PaymentTypes,
  Totals as TotalsType,
} from "../../../models/checkout_model";
import { formatNumber } from "../../../middleware/common-functions";
import AtratoComp from "./atratoComponent";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

type CartResumeProps = {
  totals: TotalsType;
  error_message: any;
  cart_id?: string;
  updateSection: any;
  next: any;
  disabled_btn: boolean | "hidden";
  placeOrder: any;
  handleCartData: any;
  cardData: CardData;
  payment_type: PaymentTypes;
};

type PaypalSettingsType = {
  active: boolean;
  mode: "sandbox" | "production";
  sandbox_client_id: string;
  production_client_id: string;
};

type Paypal = {
  color?: "gold" | "blue" | "silver" | "white" | "black";
  height?: number;
  label?:
    | "paypal"
    | "checkout"
    | "buynow"
    | "pay"
    | "installment"
    | "subscribe"
    | "donate";
  layout?: "vertical" | "horizontal";
  shape?: "rect" | "pill";
  tagline?: boolean;
};

const CartResume: React.FC<CartResumeProps> = ({
  totals,
  error_message,
  updateSection,
  next,
  disabled_btn,
  placeOrder,
  handleCartData,
  cardData,
  cart_id,
  payment_type,
}) => {
  const { getCVCProps } = usePaymentInputs();
  const paypal: PaypalSettingsType = {
    active: true,
    mode: "production",
    sandbox_client_id:
      "AWKtako7BTOmIZJK-WBrbUnxB3VvZgwHQ04Eq8wlssneJoda-pGqV36QSs8FhjqoewI-AeU0nKGAD-FZ",
    production_client_id:
      "AQZtrYmtA4-ULK4bOPO3kvg8I-DoQfiCsxYyKX8stKqwxkaQDm9y73JuhSJHPKrIGuOfBno1tZTQftQJ",
  };
  return (
    <div className={`cart_resume ${next ? "xs_hidden" : ""}`}>
      <Totals totals={totals} />
      {error_message && (
        <div className="alert alert-danger" id="alert-shake">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error_message}
        </div>
      )}
      {payment_type === "online" && !next && cardData && cardData.getcvv ? (
        <CvvConfirm
          getCVCProps={getCVCProps}
          cvv={cardData.cvv}
          handleCartData={handleCartData}
        />
      ) : null}
      {payment_type === "atrato" && !next ? (
        <div className="alert alert-info">
          <i className="fas fa-exclamation-circle mr-2"></i>
          <span>
            ¡Ya casi tendrás tu compra!, para obtener tu producto es necesario
            que completes el proceso de solicitud de crédito con Atrato. En el
            siguente paso serás redirigido a la solicitud de crédito de manera
            automática, <br />
            Tu orden será procesada cuando termines el proceso en Atrato
          </span>
        </div>
      ) : null}
      {payment_type === "paypal" && !next ? (
        <PayPalScriptProvider
          options={{
            "client-id":
              paypal.mode === "sandbox"
                ? paypal.sandbox_client_id
                : paypal.production_client_id,
            currency: "MXN",
            components: "buttons",
            "disable-funding": "card",
          }}
        >
          <ButtonWrapper
            placeOrder={placeOrder}
            showSpinner={false}
            total={totals.total}
          />
        </PayPalScriptProvider>
      ) : null}
      <AtratoComp
        totals={totals}
        show={payment_type === "atrato" && !next}
        orderId={cart_id}
      />
      {disabled_btn === "hidden" ? null : (
        <button
          onClick={next ? updateSection : placeOrder}
          type="button"
          disabled={disabled_btn}
          data-next={next}
          className={`btn btn-block btn-cart text-capitalize py-4 py-md-3 fixed ${
            payment_type === "atrato" || payment_type === "paypal"
              ? "d-none"
              : ""
          }`}
        >
          {next ? "Continuar" : "Confirmar Pedido"}
        </button>
      )}
    </div>
  );
};

const Totals: React.FC<{ totals: TotalsType }> = ({ totals }) => (
  <div className="totals ">
    <p>
      <span>Subtotal</span> <span>${formatNumber(totals.subtotal)}</span>
    </p>
    <p>
      <span>IVA</span> <span>${formatNumber(totals.tax)}</span>
    </p>
    <hr />
    {totals.discount ? (
      <p>
        <span>Descuentos</span>{" "}
        <span className="text-danger">-${formatNumber(totals.discount)}</span>
      </p>
    ) : null}
    <p>
      <span>Costo Envío</span> <span>${formatNumber(totals.shipping)}</span>
    </p>
    {totals.comission && totals.comission > 0 ? (
      <p>
        <span>Comisión Pago a Meses</span>{" "}
        <span>${formatNumber(totals.comission)}</span>
      </p>
    ) : null}
    <hr />
    <p>
      <span>Total</span>{" "}
      <span>
        $
        {totals.comission && totals.comission > 0
          ? formatNumber(totals.total + totals.comission)
          : formatNumber(totals.total)}
      </span>
    </p>
  </div>
);

export const CvvConfirm: React.FC<{
  cvv: string | undefined;
  handleCartData: any;
  getCVCProps: any;
}> = ({ cvv, handleCartData, getCVCProps }) => {
  return (
    <div className="form-group mb-3 ">
      <label htmlFor="inputPassword4">Confirma tu CVV</label>
      <input
        {...getCVCProps({
          onChange: handleCartData,
        })}
        data-type="cvv"
        type="text"
        className="form-control"
        value={cvv}
      />
    </div>
  );
};

type ButtonWrapperProps = {
  showSpinner: boolean;
  total: number;
  placeOrder: any;
};
const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  showSpinner,
  total,
  placeOrder,
}) => {
  const style: Paypal = { layout: "vertical", label: "paypal" };
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  console.log("cartResume.tsx:213 | total", total);

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: "MXN",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpinner]);

  if (total <= 0) return null;

  return (
    <PayPalButtons
      style={style}
      disabled={false}
      forceReRender={[total, "MXN", style]}
      fundingSource={undefined}
      createOrder={async (_, actions) => {
        const orderId = await actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: "MXN",
                value: total.toLocaleString("fullwide", { useGrouping: false }),
              },
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING",
          },
        });
        return orderId;
      }}
      onError={(err) => {
        console.log("payment.tsx:201 | err", err);
      }}
      onApprove={async (data, actions) => {
        try {
          const details = await actions!.order!.capture();
          console.log("payment.tsx:205 | actions", actions);
          console.log("payment.tsx:206 | details", details);
          try {
            ////TODO: Save paypal payment id on new order pay_id: details.id,
            placeOrder();
          } catch (error) {
            ////log error to sentry
            console.log("payment.tsx:232 | error", error);

            return;
          }
        } catch (error) {
          ////log error to sentry
          console.log("payment.tsx:241 | error", error);
          return;
        }
      }}
    />
  );
};

export default CartResume;
