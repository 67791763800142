import { OrderType } from "../models/order_model";
import axios from "./axios";

type setCartProps = {
  user_id: string;
  access_token?: string | boolean;
  order?: OrderType | false;
  cookie?: string | boolean;
  express?: boolean;
};

export type TokenObject = {
  access_token?: string;
  cookie?: string;
  error: any;
};

async function getToken(): Promise<TokenObject> {
  try {
    const response = await axios.post("scriptsPaymentsRappi/token");
    const { access_token, cookie } = response.data;
    return { access_token, cookie: cookie[0], error: false };
  } catch (error) {
    console.log("index.js:277 | error ", error);
    return { error: error };
  }
}

async function setCart({
  user_id,
  access_token = false,
  order = false,
  cookie = false,
  express = false,
}: setCartProps) {
  if (access_token === false || cookie === false) {
    const get_token = await getToken();
    if (
      typeof get_token.access_token === "undefined" ||
      typeof get_token.cookie === "undefined" ||
      get_token.error
    ) {
      return { error: "No response from Rappi API" };
    }
    console.log("rappi.js:18 | get_token", get_token);
    access_token = get_token.access_token;
    cookie = get_token.cookie;
  }
  try {
    const data = {
      access_token,
      cookie,
      user_id,
      order,
    };
    const response = await axios.post(
      `scriptsPaymentsRappi/${express ? "quick_cart" : "cart"} `,
      data
    );
    console.log("rappi.js:27 | response", response);
    const { id } = response.data;
    return { id, error: false };
  } catch (error) {
    console.log("index.js:277 | error ", error);
    return { error: error };
  }
}

export { getToken, setCart };
