import axios from 'axios';
import { OrderType } from '../models/order_model';
const baseURL = process.env.REACT_APP_CPLPY_ENDPOINT;

export const coppelInstance = axios.create({
  baseURL,
});

declare global {
  interface Window {
    CPLPY: any;
  }
}

type LineItemsType = {
  unitPrice: number;
  totalAmount: number;
  productSKU?: string;
  quantity: number;
  productCode: number;
  productDescription?: string;
  productName: string;
};

function makeid(length: number) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function getPurchaseRequest(config: {
  userId: string;
  price: number;
  orderDescription: string;
  order_obj: OrderType;
}): Promise<[null | { paymentRequest: string; orderId: string }, string?]> {
  const { userId, price, orderDescription, order_obj } = config;
  const urlRedirect = process.env.REACT_APP_CPLPY_URL_REDIRECT;
  const orderId = makeid(10);
  const transaction = `${userId}__${orderId}`;
  const cpl_business = process.env.REACT_APP_CPLPY_PUBLIC_KEY;
  const ip_address = localStorage.getItem('location_ip') || '';
  const cpl_signature = require('crypto')
    .createHash('md5')
    .update(
      [
        process.env.REACT_APP_CPLPY_PRIVATE_KEY,
        cpl_business,
        transaction,
        String(price),
      ].join('#')
    )
    .digest('hex');
  const lineItems: LineItemsType[] = order_obj.products.map((product) => ({
    unitPrice: product.price,
    totalAmount: product.price * product.cant,
    productSKU: product.idpart,
    quantity: product.cant,
    productCode: parseInt(product.id),
    productDescription: product.title,
    productName: product.title,
  }));
  const checkoutData = {
    cpl_business,
    cpl_transaction: transaction,
    cpl_signature,
    cpl_amount: price,
    cpl_currency: 'MXN',
    cpl_description: orderDescription,
    cpl_url_redirect: urlRedirect,
    ip_address,
    lineItems,
    additionalInfo: [
      {
        mdd: '24',
        value: 'Mejoras del Hogar',
      },
    ],
  };
  try {
    const res = await coppelInstance.post('/v2/solicitud-compra', checkoutData);
    console.log(res);
    if (res.data.code !== 'SUCCESS') {
      return [null, 'Error: ' + res.data.message];
    }
    return [{ paymentRequest: JSON.parse(res.data.data).solicitud, orderId }];
  } catch (error) {
    console.log(error);
    return [null, 'Ocurrió un error inesperado.'];
  }
}

function isCoppelReady() {
  if (window.CPLPY) {
    const blackbox = window.CPLPY.blackbox;
    const token = window.CPLPY.token;
    // document.getElementById('ModalActionBtn')?.classList.add('d-block');
    if (blackbox && token) return { blackbox, token };
    return false;
  }
  return false;
}

export async function initiatePayWithCoppel(config: {
  userId: string;
  price: number;
  orderDescription: string;
  setShowCoppelPayModal: (b: boolean) => void;
  order_obj: OrderType;
}): Promise<[null | { paymentRequest: string; orderId: string }, string?]> {
  const { setShowCoppelPayModal, ...restConfig } = config;
  setShowCoppelPayModal(true);
  const [data, error] = await getPurchaseRequest(restConfig);
  if (error) {
    alert(error);
    setShowCoppelPayModal(false);
  }
  if (data && data.paymentRequest) {
    window.CPLPY.init(data.paymentRequest);
    if (process.env.REACT_APP_ENV !== 'production') {
      window.CPLPY.sandbox = true;
    }
    window.CPLPY.open();
    return [data];
  }
  return [null, error || 'Ocurrió un error inesperado.'];
}

export async function finalizePayWithCoppel(paymentRequest: string) {
  const coppel = isCoppelReady();
  console.log('coppel.ts:99 | coppel', coppel);
  if (coppel) {
    const ip_address = localStorage.getItem('location_ip') || '';
    const { token } = coppel;
    const data = {
      cpl_solicitud: paymentRequest,
      cpl_token: token,
      cpl_ip: ip_address,
    };
    try {
      const res = await coppelInstance.post('/v2/confirmacion-pago', data);
      debugger;
      if (res.data.code !== 'SUCCESS') {
        return [null, 'Error: ' + res.data.message];
      }
      return [JSON.parse(res.data.data)];
    } catch (error) {
      console.log(error);
      return [null, 'Ocurrió un error inesperado.'];
    }
  } else {
    return [null, 'Lo sentimos, hubo un problema con Coppel Pay.'];
  }
}
