////documentation
//// https://docs.firstdata.com/org/FDMexico/node/1191
//// https://docs.firstdata.com/org/FDMexico/docs/api

///tarjetas de prueba
////4035 8740 0042 4977 = success

import axios from './axios';
import { lang } from './lang';

export const error_codes = [
  { code: '01', txt: lang('Refer to card issuer', 'Referir') },
  {
    code: '02',
    txt: lang('Refer to special conditions for card issuer', 'Referir'),
  },
  { code: '03', txt: lang('Invalid merchant Hard', 'Comercio Invalido') },
  { code: '04', txt: lang('Pick-up card Soft', 'Retener Tarjeta') },
  { code: '05', txt: lang('Do not honor', 'Llame a su banco') },
  { code: '06', txt: lang('Error', 'Error') },
  {
    code: '006',
    txt: lang(
      'Error',
      'Lo sentimos, parece que tu tarjeta no fue autorizada para realizar este pago. Favor de intentar con otra tarjeta o con otro método de pago'
    ),
  },
  {
    code: '07',
    txt: lang('Pick-up card, special condition', 'Retener Tarjeta'),
  },
  { code: '12', txt: lang('Invalid transaction ', 'Transaccion Invalida') },
  { code: '13', txt: lang('Invalid amount', 'Monto Invalido') },
  { code: '14', txt: lang('Invalid card number', 'Numero invalido') },
  { code: '15', txt: lang('No such issuer ', 'Emisor No disponible') },
  { code: '30', txt: lang('Format error ', 'Reintente') },
  { code: '33', txt: lang('Expired Card ', 'Tarjeta Expirada') },
  { code: '39', txt: lang('No credit account', 'Cuenta Invalida') },
  { code: '41', txt: lang('Lost card ', 'Tarjeta Perdida') },
  { code: '43', txt: lang('Stolen card, pick-up', 'Tarjeta Robada') },
  { code: '51', txt: lang('Not sufficient funds ', 'Fondos Insuficientes') },
  { code: '54', txt: lang('Expired card', 'Tarjeta Expirada') },
  {
    code: '55',
    txt: lang('Incorrect personal identification number', 'Pin Incorrecto'),
  },
  {
    code: '57',
    txt: lang(
      'Transaction not permitted to cardholder',
      'Operación no permitida al tarjetahabiente'
    ),
  },
  {
    code: '58',
    txt: lang(
      'Transaction not permitted to terminal ',
      'Operación no permitida al comercio'
    ),
  },
  {
    code: '61',
    txt: lang('Exceeds withdrawal amount limit', 'Limite de retiro exedido'),
  },
  { code: '62', txt: lang('Restricted card ', 'Tarjeta Restringida') },
  {
    code: '65',
    txt: lang('Exceeds withdrawal frequency limit', 'Limite de retiro exedido'),
  },
  {
    code: '68',
    txt: lang('Response received too late ', 'Tiempo exedido de respuesta'),
  },
  {
    code: '75',
    txt: lang(
      'Allowable number of PIN tries exceeded',
      'Intento de PIN exedido'
    ),
  },
  {
    code: '91',
    txt: lang('Issuer or switch is inoperative ', 'Emisor No disponible'),
  },
  {
    code: '92',
    txt: lang('Financial institution or intermediate network', 'Error de red'),
  },
  { code: '94', txt: lang('Duplicate transmission ', 'Transaccion duplicada') },
  { code: '96', txt: lang('System malfunction', 'Error de sistema') },
  { code: '5018', txt: lang('', 'No transaction found for void') },
  { code: '2303', txt: lang('', 'Invalid credit card number') },
  { code: '30051', txt: lang('', 'Transaction timed out') },
  {
    code: '5103',
    txt: lang(
      'Tarjetahabiente no completo la autenticacion',
      'Cardholder did not return from ACS'
    ),
  },
  { code: '2304', txt: lang('', 'Invalid or expired card expiry date') },
  {
    code: '5101',
    txt: lang('Autenticacion fallida', '3D Secure authentication failed'),
  },
  { code: '5315', txt: lang('', 'Transaction is already in state DECLINED') },
  { code: '5005', txt: lang('', 'FRAUD - Purchase limit exceeded') },
  { code: '5002', txt: lang('Tarjeta No Soportada', 'brand not supported') },
  { code: '5993', txt: lang('Cancelada por el Usuario', 'Cancelled by user') },
  { code: '30031', txt: lang('', 'No terminal setup') },
  { code: '7778', txt: lang('', 'Transaction timed out') },
  { code: '50698', txt: lang('', 'Incompatible referenced transaction') },
  {
    code: '5003',
    txt: lang('La orden ya existe', 'The order already exists in the database'),
  },
  {
    code: '5005',
    txt: lang('Bloqueo por Duplicidad', 'FRAUD - Duplicate lockout'),
  },
  { code: '5009', txt: lang('', 'No transaction to return found') },
  { code: '7777', txt: lang('', 'System too busy') },
  { code: '5996', txt: lang('', 'Invalid card type') },
  { code: '95001', txt: lang('', 'Currency not supported for Local cards') },
  { code: '5995', txt: lang('', 'Order too old to be referenced') },
  { code: '50004', txt: lang('', 'Invalid transaction request') },
  {
    code: '96000',
    txt: lang('Cuotas no soportodas (MSI)', 'Installment not supported'),
  },
  {
    code: '5994',
    txt: lang('', 'The selected brand does not match the card number.'),
  },
  { code: '5102', txt: lang('Sin autenticar', 'ECI 7') },
  { code: '50687', txt: lang('', 'Split Authentication not supported') },
  { code: '5022', txt: lang('', 'Not voidable') },
  { code: '5014', txt: lang('', 'Validation problem') },
  {
    code: '10601',
    txt: lang('', 'Total amount passed is more than the Return/Void amount.'),
  },
  { code: '10501', txt: lang('', 'PostAuth already performed') },
  { code: '10001', txt: lang('', 'No hay ningún totken') },
  {
    code: '10002',
    txt: lang('', 'No se reconoce el ID de la transacción (ipgTransactionId)'),
  },
];

export function saveCustomerCard(card_cata, user_id) {
  return new Promise(async function (resolve, reject) {
    const url = `scriptsPaymentsSaveCard`;
    const get_name = card_cata.name.split(' ');
    const get_lastname = get_name.pop();
    console.log('first_data.js:149 | card_cata', card_cata);
    const last4 = card_cata.number.split(' ').pop();
    const data = {
      user_id,
      card: {
        date: card_cata.date,
        number: card_cata.number,
        cvv: card_cata.cvv,
        name: get_name.join(' '),
        lastname: get_lastname,
        last4,
      },
    };
    axios
      .post(url, { ...data })
      .then((response) => {
        if (response.data.error) {
          reject(response.data.error);
        } else {
          resolve({
            id: response.data.card_id,
            last4,
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        reject(err);
      });
  });
}

export function chargeOrder(card, order_data) {
  return new Promise(function (resolve, reject) {
    const user = JSON.parse(localStorage.getItem('user'));
    const print = JSON.parse(localStorage.getItem('print')); ///vesta token
    const url = `scriptsPaymentsMakePayment`;
    axios
      .post(url, {
        user_id: user.id,
        card,
        print,
        order_data,
      })
      .then(({ data }) => {
        console.log('first_data.js:114 | data', data);
        if (data.error) {
          if (data.message) {
            return resolve({
              error: data.message.approvalCode,
            });
          }
          return resolve({
            error: data.error,
          });
        }
        return resolve(data);
      })
      .catch((err) => {
        console.log('first_data.js:114 | err', err);
        reject({ error: err });
      });
  });
}

export function makePayment(data) {
  return new Promise(function (resolve, reject) {
    const url = `scriptsPaymentsMakePaymentDev`;
    axios
      .get(url, {
        userData: data.userData,
        token: data.data.id,
        session_id: data.data.deviceSessionId,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log('err', err);
        reject(err);
      });
  });
}

export function getPaymentError(code) {
  console.log('first_data.js:217 | code', code);
  const defualt_message =
    'Algo salió mal, favor de intentar de nuevo. Si el problema persiste favor de comunicarse por nuestro chat';
  if (!code) {
    return defualt_message;
  }
  let code_value;
  if (code.error) {
    code_value = code.error.code;
  } else if (code.processor) {
    code_value = code.processor.responseCode;
  } else {
    code_value = code;
  }
  const reponse = error_codes.find((i) => i.code == code_value);
  return reponse ? reponse.txt : defualt_message;
}

export function fiservErrorCode(code) {
  code = code.replace(/[^a-zA-Z0-9]/g, '');

  switch (code) {
    case 'N507163DSecureauthenticationfailed':
      return 'Pago Declinado. Falló la autenticación 3D Secure. Favor de intentar de nuevo';
    case 'N0101REFERRAL':
      return 'Referir';
    case 'N5105DECLINED ':
      return 'Error en el pago. Llame a su banco';
    case 'N0102REFERRAL':
      return 'Referir';
    case 'N54EXPIREDCARD':
      return 'Tarjeta Expirada';
    case 'N0303INVLDMERID':
      return 'Comercio Invalido';
    case 'N6004HOLDCALLCTR':
      return 'RetenerTarjeta';
    case 'N5105DECLINED':
      return 'Llame a su banco';
    case 'N0106DECLINED':
      return 'Tarjeta Declinada, favor de intentar con otra tarjeta';
    case 'N6007HOLDCALLCTR':
      return 'Su tarjeta aparece como robada, favor de comunicarse con su banco';
    case 'N1212INVTRAN':
      return 'Transaccion Invalida';
    case 'N1313INVAMT':
      return 'Monto Invalido';
    case 'N1414INVACCTNUM':
      return 'Numero invalido';
    case 'N5115DECLINED':
      return 'Emisor No disponible';
    case 'N9130PLEASERETRY':
      return 'Reintente';
    case 'N54:33EXPIREDCARD':
      return 'Tarjeta Expirada';
    case 'N5139DECLINED':
      return 'Cuenta Invalida';
    case 'N6041HOLDCALLCTR':
      return 'Tarjeta Perdida';
    case 'N6043HOLDCALLCTR ':
      return 'Tarjeta Robada';
    case 'N5151DECLINED':
      return 'Fondos Insuficientes';
    case 'N5454EXPIREDCARD':
      return 'Tarjeta Expirada';
    case 'N5555INCORRECTPIN':
      return 'Pin Incorrecto';
    case 'N5757TXNNOTALLOW':
      return 'Operación no permitida al tarjetahabiente';
    case 'N6358SERVNOTALLOWED':
      return 'Operación no permitida al comercio';
    case 'N5161TRYAGAINLATER':
      return 'Limite de retiro exedido';
    case 'N6362SERNOTALLOW':
      return 'Tarjeta Restringida';
    case 'N5165TRYAGAINLATER':
      return 'Limite de retiro exedido';
    case 'N9168PLEASERETRY':
      return 'Tiempo exedido de respuesta';
    case 'N7575PINRETRYMAX':
      return 'Intento de PIN exedido';
    case 'N9191PLEASERETRY':
      return 'Emisor No disponible';
    case 'N9192PLEASERETRY':
      return 'Error de red';
    case 'N9494DUPLICATETRAN':
      return 'Transaccion duplicada';
    case 'NCE96SYSTEMPROBLEM':
      return 'Error de sistema';
    case 'Code':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5018':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N2303':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N30051':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5103':
      return 'Tarjetahabiente no completo la autenticacion';
    case 'N2304':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5101':
      return 'Autenticacion fallida';
    case 'N5315':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5005':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5002':
      return 'Tarjeta No Soportada';
    case 'N5993':
      return 'Cancelada por el Usuario';
    case 'N30031':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N7778':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N50698':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5003':
      return 'La orden ya existe';
    case 'N5005':
      return 'Bloqueo por Duplicidad';
    case 'N5009':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N7777':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5996':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N95001':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5995':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N50004':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N96000':
      return 'Cuotas no soportodas (MSI)';
    case 'N5994':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5102':
      return 'Sin autenticar';
    case 'N50687':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5022':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N5014':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N10601':
      return 'Algo salió mal, favor de contactar a su banco';
    case 'N10501':
      return 'Algo salió mal, favor de contactar a su banco';

    default:
      return 'Código desconocido';
  }
}
