import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { db } from '../../../middleware/firebase';

import { Container, Row, Col } from 'reactstrap';

interface Idea {
  id: number;
  title: string;
  images: string[];
  products: { name: string; url: string }[];
}

const IdeasPage: React.FC = () => {
  const [idea, setIdea] = useState<Idea | false>(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchAndSetIdea = async () => {
      if (!id) return;

      try {
        const ideaData = await fetchIdea(id);
        if (ideaData) {
          setIdea(ideaData);
        } else {
          console.error('Idea not found');
        }
      } catch (error) {
        console.error('Error fetching idea:', error);
      }
    };

    fetchAndSetIdea();
  }, [id]);

  const fetchIdea = async (id: string): Promise<Idea | false> => {
    try {
      const ideaDoc = db.collection('ideas').doc(id);
      const snapshot = await ideaDoc.get();
      if (!snapshot.exists) {
        return false;
      }
      const data = snapshot.data();
      if (data) {
        const ideaData: Idea = {
          id: data.id,
          title: data.title,
          images: data.images,
          products: data.products,
        };
        return ideaData;
      }

      return false;
    } catch (error) {
      console.error('Error fetching idea:', error);
      return false;
    }
  };

  return (
    <Container className="container first-container">
      <h1 className="text-center my-4">Ideas para tu proyecto</h1>
      <Row>
        <Col md="12" className="mb-4">
          <div className="idea-container">
            <div className="details">
              <h2 className="idea-title">{idea && idea.title && idea.title}</h2>
              <ul className="product-list">
                {idea &&
                  idea.products.length &&
                  idea.products.map((product, index) => (
                    <li key={index} className="product-list-item">
                      <a
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-link"
                      >
                        {product.name}
                      </a>
                    </li>
                  ))}
              </ul>
              <div className="images_grid">
                {idea &&
                  idea.images.length &&
                  idea.images.map((images, index) => (
                    <img
                      key={index}
                      src={images}
                      alt={`${idea.title} ${index + 1}`}
                      className={
                        index % 4 === 0 ? 'idea-image large' : 'idea-image'
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IdeasPage;
