import React from "react";
import { Helmet } from "react-helmet";

const Beetrack = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          charset="UTF-8"
          language="javascript"
          src="https://beetrack-general.s3-us-west-2.amazonaws.com/widget/C_y_rPBfXmxuSA7MLOkHpg.json"
        ></script>
        <script
          async
          src="https://app.beetrack.cl/javascript_widget/beetrack_widget.js"
        ></script>
      </Helmet>
      <div className="bee_track_img">
        <div className="container text-center">
          <img src="https://cdn.mathasa.mx/banners/widgetbeetrack.png" />
        </div>
      </div>
      <div className="bee_track mb-4">
        <div className="container">
          <div
            id="beetrack_widget"
            lang="es"
            api-key="C_y_rPBfXmxuSA7MLOkHpg"
          ></div>
        </div>
      </div>
    </>
  );
};

export default Beetrack;
