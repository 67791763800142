import React, { useEffect } from 'react';
import {
  aMeses,
  formatNumber,
  renderDeliveryDates,
} from '../../../middleware/common-functions';
import { BranchItem } from '../../../models/branch_model';
import {
  OrderResumeProps,
  CardIconProps,
  MonthlyProps,
} from '../../../models/checkout_model';

const OrderResume: React.FC<OrderResumeProps> = ({
  delivery_type,
  selectedBranch,
  branch_data,
  updateSection,
  deliveryData,
  cardData,
  error_message,
  shippingcost,
  payment_type,
  totals,
  handleMonths,
  meses,
  payload,
}) => {
  let delivery_text;
  const card_number = cardData.number
    ? cardData.number.substr(cardData.number.length - 4)
    : '';
  if (delivery_type === 'shipping') {
    delivery_text = (
      <span>
        {deliveryData.name} {deliveryData.lastname} | {deliveryData.address}{' '}
        <br /> {deliveryData.city} {deliveryData.state} <br />
        {deliveryData.zip}
      </span>
    );
  } else {
    const branch: BranchItem | undefined = branch_data!.xiams.find(
      (i) => i.id == selectedBranch
    );
    delivery_text = (
      <span>
        {branch?.address} <br />
        {branch?.descrp} <br /> {branch_data?.idzalm}{' '}
      </span>
    );
  }
  return (
    <div className="col-md-8 order_resume my-4">
      {error_message && (
        <div className="alert alert-danger d-md-none" id="alert-shake">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error_message}
        </div>
      )}
      {meses && payment_type === 'online' ? (
        <Monthly
          meses={meses}
          total={totals.total}
          handleMonths={handleMonths}
        />
      ) : null}
      <h5>Forma de Pago</h5>
      <CardIcon
        icon={
          payment_type === 'online'
            ? 'far fa-credit-card'
            : payment_type === 'rappi'
            ? 'fas fa-mobile-alt'
            : payment_type === 'atrato'
            ? 'fas fa-wallet'
            : 'fas fa-money-check-alt'
          //  TODO: ADD ICON HERE
        }
        title={
          payment_type === 'online'
            ? 'Pago con Tarjeta Online'
            : payment_type === 'rappi'
            ? 'Pago con RappiPay'
            : payment_type === 'coppel'
            ? 'Pago con Coppel Pay'
            : payment_type === 'atrato'
            ? 'Pago con Atrato Pago'
            : 'Transferencia Electrónica'
        }
        desc={
          payment_type === 'online'
            ? `Terminación ${card_number}`
            : payment_type === 'rappi'
            ? 'Podrás completar el pago desde tu app de Rappi'
            : payment_type === 'atrato'
            ? 'Podrás financiar esta compra hasta en 18 cómodas mensualidades, sin necesidad de tener tarjeta de crédito. Después de dar clic en "confirmar pedido", se te dirigirá a Atrato Pago para continuar con tu proceso de compra.'
            : ''
        }
        cta="Modificar"
        section="payment"
        clickFun={updateSection}
      />

      <h5>Detalles de Entrega</h5>
      <CardIcon
        icon={
          delivery_type === 'shipping'
            ? 'fas fa-shipping-fast'
            : 'fas fa-map-marker-alt'
        }
        title={
          delivery_type === 'shipping'
            ? 'Envío a Domicilio'
            : 'Recoger en Sucursal'
        }
        desc={delivery_text}
        cta="Cambiar"
        section="delivery"
        clickFun={updateSection}
      />
      <CardIcon
        icon="fas fa-stopwatch"
        title="Tiempo de Entrega"
        desc={renderDeliveryDates(shippingcost.shippingCosts)}
      />

      {error_message && (
        <div className="alert alert-danger d-md-none" id="alert-shake">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error_message}
        </div>
      )}

      <small className="text-help mt-2 d-block text-muted">
        Necesitas ayuda con tu pedido o tienes dudas? <br />
        Estámos para atenderte en el correo <b>servicio@mathasa.com.mx</b> o al
        teléfono <b>614-442-9000</b>
      </small>
      {payload && <FromSecure payload={payload} />}
    </div>
  );
};

export const CardIcon: React.FC<CardIconProps> = ({
  icon,
  title,
  desc,
  cta,
  section,
  clickFun,
  size,
}) => (
  <div className={`card card_icon w-100 mb-4 ${size}`}>
    <div className="card-body">
      <div className="row jus">
        <div className="col-md-2">
          <div className="icon_rounded white">
            <i className={icon}></i>
          </div>
        </div>
        <div className="col-md-7">
          <p>{title}</p>
          <small>{desc}</small>
        </div>
        <div className="col-md-3 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-link text-capitalize text-blue btn-sm btn-block"
            onClick={clickFun}
            data-next={section}
          >
            {cta}
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Monthly: React.FC<MonthlyProps> = ({ meses, total, handleMonths }) => {
  return (
    <div className="mb-3 ">
      <h5>Plazo para Pagos</h5>
      <select
        className="form-control"
        onChange={handleMonths}
        disabled={total < 1500}
      >
        <option value="1">Un solo pago a ${formatNumber(total)}</option>
        {total < 1500 ? (
          <option value="1">
            Hey! no le ande moviendo a páginas que no son suyas. Ya lo vi
          </option>
        ) : (
          meses.map((item, key) => {
            const { monthly_pay, total_pay } = aMeses(total, item);
            return (
              <option value={item.meses} key={`pagos_meses_${key}`}>
                {item.meses} Meses, pagas al mes ${monthly_pay} (Total $
                {total_pay})
              </option>
            );
          })
        )}
      </select>
      <div
        className={`alert-${total >= 1500 ? 'success' : 'warning'} mt-1 p-3`}
      >
        <p className="m-0">
          {total >= 1500
            ? 'Tarjetas BBVA solo se puede pago en 1 sola exhibición'
            : 'Pago a meses sólo dispoible en compras mayores a $1,500'}
        </p>
      </div>
    </div>
  );
};

const FromSecure = ({ payload }: { payload: any }) => {
  if (payload.cReq) {
    return (
      <form action={payload.acsURL} method="POST" id="payload_3d_secure">
        <input type="hidden" name="TermUrl" value={payload.termURL} />
        <input type="hidden" name="creq" value={payload.cReq} />
        <input
          type="hidden"
          name="threeDSSessionData"
          value={payload.sessionData || ''}
        />
      </form>
    );
  } else if (payload.methodForm) {
    return <IframeComponent methodForm={payload.methodForm} />;
  } else {
    return null;
  }
};

type IframeComponentProps = {
  methodForm: string;
};
const IframeComponent: React.FC<IframeComponentProps> = ({ methodForm }) => {
  useEffect(() => {
    ///add methodForm to the DOM
    const div = document.getElementById('fiserv_iframe') as HTMLDivElement;
    div.innerHTML = methodForm;
    setTimeout(() => {
      //@ts-ignore
      document.getElementById('tdsMmethodForm')!.submit();
    }, 2000);
  }, []);
  // Convert escaped HTML characters back to their original form

  return <div id="fiserv_iframe" />;
};

export default OrderResume;
