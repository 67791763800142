import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { db } from '../../../middleware/firebase';

interface Idea {
  id: number;
  title: string;
  images: string[];
  products: { name: string; url: string }[];
}

const indicatorStyles = {
  background: '#000000',
  width: '8px',
  height: '8px',
  display: 'inline-block',
  margin: '0 8px',
};

const IdeasPage: React.FC = () => {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ideasPerPage = 3;

  useEffect(() => {
    const fetchAndSetIdeas = async () => {
      try {
        const ideasData = await fetchIdeas();
        setIdeas(ideasData);
      } catch (error) {
        console.error('Error fetching ideas:', error);
      }
    };

    fetchAndSetIdeas();
  }, []);

  const fetchIdeas = async (): Promise<Idea[]> => {
    const ideasCollection = db.collection('ideas');
    const ideas: Idea[] = [];

    try {
      const snapshot = await ideasCollection.get();
      snapshot.forEach((doc) => {
        const data = doc.data();
        ideas.push({
          id: data.id,
          title: data.title,
          images: data.images,
          products: data.products,
        });
      });
    } catch (error) {
      console.error('Error fetching ideas:', error);
    }

    return ideas;
  };

  const lastIndex = currentPage * ideasPerPage;
  const firstIndex = lastIndex - ideasPerPage;
  const currentIdeas = ideas.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(ideas.length / ideasPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container className="container first-container">
      <h1 className="text-center my-4">Ideas para tu proyecto</h1>
      <Row>
        {currentIdeas.map((idea) => (
          <Col md="6" key={idea.id} className="mb-4">
            <div
              className="idea-container"
              onClick={() => (window.location.href = `/idea/${idea.id}`)}
            >
              <div className="details">
                <h2 className="idea-title">{idea.title}</h2>
                <ul className="product-list">
                  {idea.products.map((product, index) => (
                    <li key={index} className="product-list-item">
                      <a
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-link"
                      >
                        {product.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  if (idea.images.length > 1 && isSelected) {
                    return (
                      <li
                        className="carouselIndicator"
                        style={{
                          ...indicatorStyles,
                          background: '#000',
                          width: 12,
                          height: 12,
                        }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      />
                    );
                  }
                  if (idea.images.length > 1) {
                    return (
                      <li
                        className="carouselIndicator"
                        style={indicatorStyles}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    );
                  }
                }}
              >
                {idea.images.map((image_url, key) => (
                  <img
                    src={image_url}
                    key={`idea-image-${key}idea.title`}
                    alt={idea.title}
                    className="idea-image"
                  />
                ))}
              </Carousel>
            </div>
          </Col>
        ))}
      </Row>
      <Pagination aria-label="Page navigation" className="pagination">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => handlePageChange(1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePageChange(currentPage - 1)}
          />
        </PaginationItem>
        {[...Array(totalPages)].map((_, index) => (
          <PaginationItem active={index + 1 === currentPage} key={index}>
            <PaginationLink onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => handlePageChange(totalPages)} />
        </PaginationItem>
      </Pagination>
    </Container>
  );
};

export default IdeasPage;
