import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';

import ProductMenu from './productsMenu';
const Nav = () => {
  const [productActive, productSetActive] = useState(false);
  const [conoceActive, conoceSetActive] = useState(false);
  const authContext = useContext(AuthContext);

  const toggleProducts = () => {
    const toggle = !productActive;
    productSetActive(toggle);
  };

  const toggleConoce = () => {
    const toggle = !conoceActive;
    conoceSetActive(toggle);
  };

  const closeAllMenus = () => {
    productSetActive(false);
    conoceSetActive(false);
  };

  return (
    <React.Fragment>
      <div
        className={`backdrop_nav ${
          (productActive && 'active') || (conoceActive && 'active')
        }`}
        onClick={closeAllMenus}
      ></div>
      <nav className="navbar navbar_items py-0 w-100 xs_hidden">
        <Link
          to="/lista/Pisos/Pisos-porcelana"
          // id={item.id}
          className={`navbar-brand`}
        >
          Pisos Porcelánicos
        </Link>
        <Link
          to="/lista/Pisos/Pisos-ceramica"
          // id={item.id}
          className={`navbar-brand`}
        >
          Pisos Cerámicos
        </Link>
        <Link
          to="/lista/Bano/Sanitarios"
          // id={item.id}
          className={`navbar-brand`}
        >
          Sanitarios
        </Link>
        <Link
          to="/lista/Boilers"
          // id={item.id}
          className={`navbar-brand`}
        >
          Boilers
        </Link>
        <Link to="/ideas" id="alto_valor" className={`navbar-brand`}>
          Ideas
        </Link>
        <button
          className="btn text-capitalize p-0 navbar-brand"
          onClick={toggleProducts}
        >
          Todos
        </button>
        <Link
          to="/promociones"
          // id={item.id}
          className={`navbar-brand alto_valor`}
        >
          Promociones
        </Link>
        {authContext.isAuth ? (
          <Link
            className="btn text-capitalize p-0 navbar-brand ml-auto"
            to="/cuenta/my-cuenta"
          >
            <i className="far fa-user-circle"></i>{' '}
            {authContext.data.displayName}
          </Link>
        ) : (
          <Link
            className="btn text-capitalize p-0 navbar-brand ml-auto"
            to="/login"
          >
            <i className="far fa-user-circle"></i> Iniciar Sesión
          </Link>
        )}
        <ProductMenu active={productActive} toggleNav={toggleProducts} />
        {/*<ConoceMenu active={conoceActive} toggleNav={toggleConoce} />*/}
      </nav>
    </React.Fragment>
  );
};

export default Nav;
